import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcessTwo from '../../components/Process/SingleProcessTwo';

import shapeImg from'../../assets/img/process/circle.png';

const Process = () => {

    return (
        <div className="gray-bg">
            <div className="rs-process modify1 pt-160 pb-120 md-pt-74 md-pb-80">
                <div className="shape-animation hidden-sm">
                    <div className="shape-process">
                        <img className="dance2" src={shapeImg} alt="images" />
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 md-text-center pr-40 md-pr-14 md-pb-80 mb-70">
                            <SectionTitle
                                sectionClass="sec-title mb-30 md-pl-30 md-pr-30"
                                subtitleClass="sub-text new"
                                subtitle="Working Process"
                                titleClass="title white-color"
                                title="Our Working Process - How We Work For Our Customers"
                            />
                            {/* <div className="btn-part mt-40">
                                <Link className="readon started" to="/contact">Contact Us</Link>
                            </div> */}
                        </div>
                        <div className="col-lg-12 sm-pl-40 sm-pr-20">
                            <div className="row">
                                <div className="col-md-6 mb-70">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number"
                                        prefixClass="number-area"
                                        prefix="1"
                                        titleClass="title"
                                        Title="Discovery"
                                        descClass="number-txt"
                                        Description="We work closely with our clients to understand their business objectives, requirements, and challenges. We conduct research, analyze data, and gather feedback from stakeholders to develop a comprehensive understanding of our clients' needs. This phase is critical to ensure that we develop solutions that are aligned with our clients' business goals."
                                    />
                                </div>
                                <div className="col-md-6 mb-70">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number"
                                        prefixClass="number-area green-bg"
                                        prefix="2"
                                        titleClass="title"
                                        Title="Planning"
                                        descClass="number-txt"
                                        Description="We develop a roadmap for the project that outlines the scope, timeline, budget, and resources required to deliver the solution. We work collaboratively with our clients to refine the plan and ensure that it meets their expectations. This phase is critical to ensure that we have a clear plan in place before we start the development process."
                                    />
                                </div>
                                <div className="col-md-6 mb-70">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number"
                                        prefixClass="number-area plum-bg"
                                        prefix="3"
                                        titleClass="title"
                                        Title="Execute"
                                        descClass="number-txt"
                                        Description="We develop the solution according to the plan. We use agile development methodologies to ensure that the solution is delivered on time and within budget. We work closely with our clients throughout the development process to ensure that they are satisfied with the progress and that the solution meets their requirements."
                                    />
                                </div>
                                <div className="col-md-6 mb-70">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number"
                                        prefixClass="number-area pink-bg"
                                        prefix="4"
                                        titleClass="title"
                                        Title="Deliver"
                                        descClass="number-txt"
                                        Description="We deliver the solution to our clients and provide support to ensure that it is successfully implemented. We conduct testing, training, and documentation to ensure that our clients are fully prepared to use the solution. We also provide ongoing support to ensure that the solution continues to meet our clients' needs and that any issues are resolved quickly and effectively. This phase is critical to ensure that our clients get the most value from the solution and that it contributes to their business success."
                                    />
                                </div>
                                <div className="col-md-12">
                                    <SingleProcessTwo
                                        itemClass="rs-addon-number"
                                        prefixClass="number-area yellow-bg"
                                        prefix="5"
                                        titleClass="title"
                                        Title="Ongoing Support"
                                        descClass="number-txt"
                                        Description="We provide ongoing support to our clients to ensure that their software solutions continue to meet their needs and that any issues are resolved quickly and effectively. Our support is available around the clock to provide assistance and guidance to our clients. We also offer maintenance and upgrade services to ensure that our clients' solutions remain up-to-date and secure."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Process;