import SectionTitle from '../../components/Common/SectionTitle';

import techImg1 from '../../assets/img/technology/style2/1.png';
import techImg2 from '../../assets/img/technology/style2/2.png';
import techImg3 from '../../assets/img/technology/style2/3.png';
import techImg4 from '../../assets/img/technology/style2/4.png';
import techImg5 from '../../assets/img/technology/style2/5.png';
import techImg6 from '../../assets/img/technology/style2/6.png';
import techImg7 from '../../assets/img/technology/style2/7.png';
import techImg8 from '../../assets/img/technology/style2/8.png';
import techImg9 from '../../assets/img/technology/style2/9.png';
import techImg10 from '../../assets/img/technology/style2/10.png';
import techImg11 from '../../assets/img/technology/style2/11.png';
import techImg12 from '../../assets/img/technology/style2/12.png';
import techImg13 from '../../assets/img/technology/style2/13.png';
import techImg14 from '../../assets/img/technology/style2/14.png';
import techImg15 from '../../assets/img/technology/style2/15.png';
import techImg16 from '../../assets/img/technology/style2/16.png';
import techImg17 from '../../assets/img/technology/style2/17.png';
import techImg18 from '../../assets/img/technology/style2/18.png';


const Technology = (props) => {
    return (
        <div className="rs-technology gray-bg pt-120 pb-130 md-pt-70 md-pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text gold-color"
                    subtitle="Technology Index"
                    titleClass="title title2"
                    title="What Technology We Are Using For Our Valued Customers"
                />
                <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg1} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg2} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg3} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg4} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg5} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg6} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg7} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg8} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg9} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg10} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg11} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg12} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg13} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg14} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg15} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg16} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg17} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="technology-item">
                            <a href="#">
                                <div className="logo-img">
                                    <img src={techImg18} alt="" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-12 text-center">
                        <div className="btn-part mt-30">
                            <a className="readon started" href="#">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Technology