import React from 'react';
import SectionTitle from '../Common/SectionTitle';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';

const OnepageContactPart = (props) => {
    const { contactSectionClass, contactSectionTitleClass, contactSubTitleClass, contactBoxClass, submitBtnClass } = props;
    return (
        <div id="rs-contact" className={contactSectionClass ? contactSectionClass : 'rs-contact pt-120 md-pt-80'}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 md-mb-60">
                        <ContactInfo 
                            contactBoxClass = {contactBoxClass}
                        />
                    </div>
                    <div className="col-lg-8 pl-70 lg-pl-50 md-pl-14">
                        <div className="contact-widget onepage-style">
                            <SectionTitle
                                sectionClass="sec-title2 mb-40"
                                subtitleClass={contactSubTitleClass ? contactSubTitleClass : 'sub-text contact mb-14'}
                                subtitle="Get In Touch"
                                titleClass={contactSectionTitleClass ? contactSectionTitleClass : 'title testi-title'}
                                title="Fill The Form Below"
                            />

                            {/* Contact Form */}
                            <ContactForm 
                                submitBtnClass= {submitBtnClass}
                            />
                            {/* Contact Form */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="map-canvas pt-120 md-pt-80">
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.5138503359417!2d151.03241301597643!3d-33.95362983089938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bff1036a0edf%3A0xd92c379bcdb8d716!2sIDL%20Projections%20Pty%20Ltd!5e0!3m2!1sen!2sau!4v1659062034569!5m2!1sen!2sau" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105904.44321075013!2d150.96456385930676!3d-33.95362983089938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bff1036a0edf%3A0xd92c379bcdb8d716!2sIDL%20Projections%20Pty%20Ltd!5e0!3m2!1sen!2sau!4v1659065335944!5m2!1sen!2sau" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
}

export default OnepageContactPart;