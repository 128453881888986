import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';
import ProgressBar from '../../components/Elements/Progress/ProgressBar';

// About Image
import AboutImage from '../../assets/img/about/about-6.png';

const About = (props) => {

    return (
        <div id="rs-about" className="rs-about style2 modify2 gray-bg pt-120 pb-120 md-pt-70 md-pb-80">
            
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-30"
                            subtitleClass="sub-text style4-bg"
                            subtitle="About Us"
                            titleClass="title pb-20"
                            title="We Are Increasing Business Success With Technology"
                            descClass="desc"
                            description="Working in IT services developing software applications and data solutions for clients since 2010."
                        />
                        {/* Section Title End */}
                        {/* <div className="rs-skillbar style1 home4">
                            <ProgressBar
                                completed={92}
                                height={7}
                                boxbg={"#eeeeee"}
                                figurebg={"#FD6509"}
                                ProgressBox="progress-box"
                                ProgressFigure="progress-figure"
                                ProgressCount="Progress-count"
                                ProgressLabel="progress-label"
                                label="Software Development"
                                ProgressParent="main-div"
                            />
                            <ProgressBar
                                completed={80}
                                height={7}
                                boxbg={"#eeeeee"}
                                figurebg={"#03DEA8"}
                                ProgressBox="progress-box"
                                ProgressFigure="progress-figure"
                                ProgressCount="Progress-count"
                                ProgressLabel="progress-label"
                                label="Cyber Security"
                                ProgressParent="main-div"
                            />
                            <ProgressBar
                                completed={95}
                                height={7}
                                boxbg={"#eeeeee"}
                                figurebg={"#0A99FD"}
                                ProgressBox="progress-box"
                                ProgressFigure="progress-figure"
                                ProgressCount="Progress-count"
                                ProgressLabel="progress-label"
                                label="Artificial Intelligence"
                                ProgressParent="main-div"
                            />
                            <ProgressBar
                                completed={78}
                                height={7}
                                boxbg={"#eeeeee"}
                                figurebg={"#F00674"}
                                ProgressBox="progress-box"
                                ProgressFigure="progress-figure"
                                ProgressCount="Progress-count"
                                ProgressLabel="progress-label"
                                label="Web Development"
                                ProgressParent="main-div"
                            />
                        </div> */}
                        <div className="btn-part mt-54">
                            <Link className="readon started" to="/about">Get Started</Link>
                        </div>
                    </div>
                    <div className="col-lg-6 pl-64 md-pl-14">
                        <div className="about-img">
                            <img
                                src={AboutImage}
                                alt="about"
                            /> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;