import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import hoverIcon1 from '../../assets/img/service/style2/hover-img/1.png';
import mainIcon2 from '../../assets/img/service/style2/main-img/2.png';
import hoverIcon2 from '../../assets/img/service/style2/hover-img/2.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import hoverIcon3 from '../../assets/img/service/style2/hover-img/3.png';
import mainIcon4 from '../../assets/img/service/style2/main-img/4.png';
import hoverIcon4 from '../../assets/img/service/style2/hover-img/4.png';
import mainIcon5 from '../../assets/img/service/style2/main-img/5.png';
import hoverIcon5 from '../../assets/img/service/style2/hover-img/5.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import hoverIcon6 from '../../assets/img/service/style2/hover-img/6.png';

const Service = () => {

    return (
        <div id="rs-service" className="rs-services style3 pt-110 pb-100 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text"
                    subtitle="Services"
                    titleClass="title testi-title"
                    title="Our Featured Services"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={hoverIcon1}
                            Title="Software Development"
                            // serviceURL="software-development"
                            Text="IDL Software offers software consulting services to help businesses develop software solutions that meet their specific needs. We can help businesses assess their current software infrastructure, identify areas for improvement, and develop a roadmap for future development. Our team has expertise in various industries and can provide guidance on best practices, industry standards, and emerging technologies."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon2}
                            hoverIcon={hoverIcon2}
                            Title="Web Development"
                            // serviceURL="web-development"
                            Text="Developing web-based solutions is one of the core services that IDL Software offers. They can help businesses design and develop custom web applications, websites, and e-commerce platforms that are tailored to their specific needs. Our team of web developers uses the latest technologies and frameworks to create high-performance, scalable, and secure web solutions that meet industry standards."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon3}
                            hoverIcon={hoverIcon3}
                            Title="Analytic Solutions"
                            // serviceURL="analytic-solutions"
                            Text="IDL Software can help businesses gain insights into their data and make data-driven decisions. They offer data analytics consulting services, data visualization services, and data warehousing services. Our team can help businesses extract insights from their data and develop predictive models that can help them make informed decisions."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item paste-bg"
                            prefix="04"
                            mainIcon={mainIcon4}
                            hoverIcon={hoverIcon4}
                            Title="Clould & DevOps"
                            // serviceURL="clould-&-devOps"
                            Text="IDL Software can help businesses optimize their cloud infrastructure and implement DevOps practices to improve their software development processes. They offer cloud consulting services, cloud migration services, and cloud infrastructure management services. Our DevOps consulting services can help businesses streamline their software development processes, improve collaboration, and reduce time-to-market."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item purple-bg"
                            prefix="05"
                            mainIcon={mainIcon5}
                            hoverIcon={hoverIcon5}
                            Title="MVP Development"
                            // serviceURL="product-&-design"
                            Text="Minimum Viable Product (MVP) development is an essential service that IDL Software offers. They can help businesses develop an MVP quickly and cost-effectively, which can help them validate their ideas and get feedback from customers. Our team of developers uses agile development methodologies to ensure that the MVP is delivered on time and within budget."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item green-bg"
                            prefix="06"
                            mainIcon={mainIcon6}
                            hoverIcon={hoverIcon6}
                            Title="Report Automation Solutions"
                            // serviceURL="database-administrator"
                            Text="Reporting is an essential part of many businesses, but it can be time-consuming and error-prone. IDL Software can help businesses automate their reporting processes and save time and resources. We offer custom report automation solutions that are tailored to our clients' specific needs. Our solutions can help businesses generate reports quickly, reduce errors, and improve the accuracy of their data."
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;